import React, { useCallback, useContext, useState } from 'react';
import validator from 'validator';
import Box from '../components/box/box';
import Input from '../components/input/input';
import A from '../components/a/a';
import Button from '../components/button/button';
import Page from '../components/page/page';
import toast from 'react-hot-toast';
import Api from '../api/api';
import { Context } from '../context/context';
import modals from '../modals/modals';
import { useNavigate } from 'react-router-dom';

function Login() 
{
	const navigate = useNavigate();
	const context = useContext(Context);
	const [email, setEmail] = useState<string>('');
	const [password, setPassword] = useState<string>('');
	const [loggingIn, setLoggingIn] = useState(false);

	const onEmailChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(e.target.value);
	}, []);

	const onPasswordChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setPassword(e.target.value);
	}, []);

	const login = useCallback(async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => 
	{
		e.preventDefault();

		let finalEmail = email.trim();

		if (!finalEmail || !password)
		{
			toast.error("Email and password are required.");	
			return;
		}

		if (!validator.isEmail(finalEmail))
		{
			toast.error("Invalid email address.");
			return;
		}

		if (password.length < 4)
		{
			toast.error("Invalid password.");
			return;
		}

		setLoggingIn(true);
		try
		{
			const response = await Api.post("/login", { email: finalEmail, password });
			if (response.status)
			{
				const user: modals.User = {
					id: response.message.id,
					name: response.message.name,
					email: response.message.email,
					newsletter: response.message.newsletter,
					pswdSet: response.message.pswd_set
				};
				context.setUser(user);
				navigate("/", { replace: true });
			}	
			else
			{
				throw new Error(response.error.message);
			}
		}
		catch (err: any)
		{
			toast.error(err.message);
		}

		setLoggingIn(false);
	}, 
	[email, password, context, navigate]);

	return (
		<Page isAuthRequired={false}>
			<div className="w-screen h-screen flex items-center justify-center">
				<div className="w-full max-w-sm">
					<div className="w-full flex items-center justify-center">
						<img className="w-6 h-6" src="/images/logo.png" alt='logo' />
						<h1 className="ml-2 font-logo font-bold text-2xl text-white">
							Hoverify
						</h1>
					</div>
					<Box className="mt-4">
						<h2 className="font-body text-white text-xl font-medium">
							Sign in to your account
						</h2>
						<form className="mt-8 flex flex-col gap-4">
							<Input
								value={email}
								label="Email address"
								placeholder="Your email address"
								onChange={onEmailChange}
							/>
							<Input
								type="password"
								value={password}
								label="Password"
								placeholder="••••••••"
								onChange={onPasswordChange}
							/>

							<div className="flex justify-end text-sm text-slate-400">
								<A href="/forgot-password">
									Forgot your password?
								</A>
							</div>

							<Button 
								theme='blue' 
								onClick={login} 
								loading={loggingIn}
							>
								Sign in
							</Button>
						</form>
					</Box>
				</div>
			</div>
		</Page>
	);
}

export default Login;